<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<!-- remarks to Operation -->
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h1 class="header-title">Remarks to Operation</h1>
						<div class="row">
							<div class="col-12">
								<form @submit.prevent="submitData">
									<div class="form-row">
										<div class="col-md-12">
											<!-- <label for="">Remarks to Operation </label> -->
											<tinymce id="d2" v-model="dataQuotation.remarks_operation"
															 :other_options="remarksOperations">
											</tinymce>
										</div>

									</div>

								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h1 class="header-title">
							Form Quotation
						</h1>
						<div class="row mt-3">
							<div class="col-12">
								<form @submit.prevent="submitData">
									<div class="form-row">
										<div class="col-md-6">
											<!-- <label for="tanggalInvoice">Tanggal </label> -->
											<div class="form-group">
												<multiselect id="customer" v-model="dataQuotation.customer"
																		 placeholder="Choose a Customer" :searchable="true"
																		 :allow-empty="true" :clearable="true" :loading="isLoading"
																		 :close-on-select="true" :options="listCustomer" label="name"
																		 required></multiselect>
											</div>
										</div>
										<div class="col-md-6">
											<!-- <label for="tanggalInvoice">Tanggal </label> -->
											<div class="form-group">
												<input v-model="dataQuotation.subject" type="text" class="form-control"
															 placeholder="Subject" required />
											</div>
										</div>
										<div class="col-md-6">
											<!-- <label for="tanggalInvoice">Tanggal </label> -->
											<div class="form-group">
												<input v-model="dataQuotation.attn" type="text" class="form-control"
															 placeholder="Attn" required />
											</div>
										</div>
										<div class="col-md-6">
											<!-- <label for="tanggalInvoice">Tanggal </label> -->
											<div class="form-group">
												<multiselect id="pod" v-model="dataQuotation.pod"
																		 placeholder="Port of Delivery" :searchable="true"
																		 :allow-empty="true" :clearable="true" :loading="isLoading"
																		 :close-on-select="true" :options="listPod" label="name_port"
																		 required></multiselect>
											</div>
										</div>
										<div class="col-md-6">
											<!-- <label for="tanggalInvoice">Tanggal </label> -->
											<div class="form-group">
												<multiselect id="pol" v-model="dataQuotation.pol"
																		 placeholder="Port of Loading" :searchable="true" :allow-empty="true"
																		 :clearable="true" :loading="isLoading" :close-on-select="true"
																		 :options="listPol" label="name_port" required></multiselect>
											</div>
										</div>
										<div class="col-md-6">
											<!-- <label for="tanggalInvoice">Tanggal </label> -->
											<div class="form-group">
												<multiselect id="term_quotation" v-model="dataQuotation.term"
																		 placeholder="Term Quotation" :searchable="true" :allow-empty="true"
																		 :clearable="true" :loading="isLoading" :close-on-select="true"
																		 :options="listTerm" label="name" required></multiselect>
											</div>
										</div>
										<div class="col-md-12">
											<label for="">Remarks</label>
											<tinymce id="d1" v-model="dataQuotation.remarks"
															 :other_options="remarksOptions" required>
											</tinymce>
										</div>
										<!-- local Charge -->
										<div class="col-md-12 mt-3">
											<!-- Using value -->
											<b-button v-b-toggle="'local_charge'" class="m-1" variant="info">Add Local
												Charge</b-button>

											<!-- Element to collapse -->
											<b-collapse id="local_charge">
												<b-card>
													<div class="row">
														<div class="col-md-2">
															<div class="radio radio-blue mb-2">
																<input id="origin" v-model="dataQuotation.type_charge"
																			 type="radio"
																			 name="type_local" value="origin" checked />
																<label for="origin">Origin</label>
															</div>
														</div>
														<div class="col-md-2">
															<div class="radio radio-info mb-2">
																<input id="destination" v-model="dataQuotation.type_charge"
																			 type="radio"
																			 name="type_local" value="destination" />
																<label for="destination">Destination</label>
															</div>
														</div>
													</div>
													<div v-for="(inputLocalCharge, k) in inputsLocalCharge"
															 :key="k" class="form-row">
														<div class="col-md-3">
															<div class="form-group">
																<multiselect id="local_name"
																						 v-model="inputLocalCharge.name"
																						 placeholder="Kind of Charge" :searchable="true"
																						 :allow-empty="true" :clearable="true"
																						 :loading="isLoading" :close-on-select="true"
																						 :options="listItem" label="item_name" required>
																</multiselect>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<input v-model="inputLocalCharge.rate" type="text"
																			 class="form-control" placeholder="Rate" required />
															</div>
														</div>
														<div class="col-md-2">
															<multiselect id="local_curr" v-model="inputLocalCharge.curr"
																					 placeholder="Currency" :searchable="true"
																					 :allow-empty="true" :clearable="true"
																					 :loading="isLoading" :close-on-select="true"
																					 :options="listCurrency" label="code" required>
															</multiselect>
														</div>
														<div class="col-md-3">
															<div class="form-group">
																<input v-model="inputLocalCharge.remarks" type="text"
																			 class="form-control" placeholder="Remarks"
																			 required />
															</div>
														</div>
														<div class="col-md-2">
															<div class="row">
																<div class="col-md-4">
																	<a v-show="k || (!k && inputsLocalCharge.length > 1)"
																		 class="btn btn-light mb-2" @click="remove(k)">
																		<i class="fas fa-minus-circle"></i>
																	</a>

																</div>
																<div class="col-md-4">
																	<a v-show="k === inputsLocalCharge.length - 1"
																		 class="btn btn-light" @click="add(k)">
																		<i class="fas fa-plus-circle"></i>
																	</a>
																</div>
															</div>
														</div>
													</div>


												</b-card>
											</b-collapse>
										</div>

										<!-- Local Charge Destination -->
										<div class="col-md-12">
											<!-- Using value -->
											<b-button v-b-toggle="'local_charge_destination'" class="m-1"
																variant="info">Add
												Local Charge Destination</b-button>

											<!-- Element to collapse -->
											<b-collapse id="local_charge_destination">
												<b-card>

													<div v-for="(inputLocalDestination, k) in inputsLocalDestination"
															 :key="k"
															 class="form-row">
														<div class="col-md-3">
															<div class="form-group">
																<multiselect id="local_destination_charge"
																						 v-model="inputLocalDestination.name"
																						 placeholder="Kind of Charges" :searchable="true"
																						 :allow-empty="true" :clearable="true"
																						 :loading="isLoading" :close-on-select="true"
																						 :options="listItem" label="item_name" required>
																</multiselect>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<input v-model="inputLocalDestination.rate" type="text"
																			 class="form-control" placeholder="Rate" required />
															</div>
														</div>
														<div class="col-md-2">
															<multiselect id="local_destination_curr"
																					 v-model="inputLocalDestination.curr"
																					 placeholder="Currency" :searchable="true"
																					 :allow-empty="true" :clearable="true"
																					 :loading="isLoading" :close-on-select="true"
																					 :options="listCurrency" label="code" required>
															</multiselect>
														</div>
														<div class="col-md-3">
															<div class="form-group">
																<input v-model="inputLocalDestination.remarks"
																			 type="text" class="form-control"
																			 placeholder="Remarks" required />
															</div>
														</div>
														<div class="col-md-2">
															<div class="row">
																<div class="col-md-4">
																	<a v-show="k || (!k && inputsLocalDestination.length > 1)"
																		 class="btn btn-light mb-2"
																		 @click="remove_local_destination(k)">
																		<i class="fas fa-minus-circle"></i>
																	</a>

																</div>
																<div class="col-md-4">
																	<a v-show="k === inputsLocalDestination.length - 1"
																		 class="btn btn-light"
																		 @click="add_local_destination(k)">
																		<i class="fas fa-plus-circle"></i>
																	</a>
																</div>
															</div>
														</div>
													</div>


												</b-card>
											</b-collapse>
										</div>

										<!-- Custom Clearance -->
										<div class="col-md-12">
											<!-- Using value -->
											<b-button v-b-toggle="'custom_clearance'" class="m-1" variant="info">Add
												Custom Clearance</b-button>

											<!-- Element to collapse -->
											<b-collapse id="custom_clearance">
												<b-card>
													<div class="row">
														<div class="col-md-2">
															<div class="radio radio-blue mb-2">
																<input id="import" v-model="dataQuotation.type_clearance"
																			 type="radio"
																			 name="type_clearance" value="import" checked />
																<label for="import">Import</label>
															</div>
														</div>
														<div class="col-md-2">
															<div class="radio radio-info mb-2">
																<input id="export" v-model="dataQuotation.type_clearance"
																			 type="radio"
																			 name="type_clearance" value="export" />
																<label for="export">Export</label>
															</div>
														</div>
													</div>
													<div v-for="(inputCustomClearance, k) in inputsCustomClearance"
															 :key="k"
															 class="form-row">
														<div class="col-md-3">
															<div class="form-group">
																<multiselect id="clearance_name"
																						 v-model="inputCustomClearance.name"
																						 placeholder="Kind of Charges" :searchable="true"
																						 :allow-empty="true" :clearable="true"
																						 :loading="isLoading" :close-on-select="true"
																						 :options="listItem" label="item_name" required>
																</multiselect>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<input v-model="inputCustomClearance.rate" type="text"
																			 class="form-control" placeholder="Rate" required />
															</div>
														</div>
														<div class="col-md-2">
															<multiselect id="local_destination_curr"
																					 v-model="inputCustomClearance.curr"
																					 placeholder="Currency" :searchable="true"
																					 :allow-empty="true" :clearable="true"
																					 :loading="isLoading" :close-on-select="true"
																					 :options="listCurrency" label="code" required>
															</multiselect>
														</div>
														<div class="col-md-3">
															<div class="form-group">
																<input v-model="inputCustomClearance.remarks"
																			 type="text" class="form-control"
																			 placeholder="Remarks" required />
															</div>
														</div>
														<div class="col-md-2">
															<div class="row">
																<div class="col-md-4">
																	<a v-show="k || (!k && inputsCustomClearance.length > 1)"
																		 class="btn btn-light mb-2"
																		 @click="remove_custom_clearance(k)">
																		<i class="fas fa-minus-circle"></i>
																	</a>

																</div>
																<div class="col-md-4">
																	<a v-show="k === inputsCustomClearance.length - 1"
																		 class="btn btn-light"
																		 @click="add_custom_clearance(k)">
																		<i class="fas fa-plus-circle"></i>
																	</a>
																</div>
															</div>
														</div>
													</div>


												</b-card>
											</b-collapse>
										</div>

										<!-- Local Charge Destination -->
										<div class="col-md-12">
											<!-- Using value -->
											<b-button v-b-toggle="'freight_pelayaran'" class="m-1" variant="info">Add
												Freight/Kapal/Pelayaran</b-button>

											<!-- Element to collapse -->
											<b-collapse id="freight_pelayaran">
												<b-card>

													<div v-for="(inputFreightPelayaran, k) in inputsFreightPelayaran"
															 :key="k"
															 class="form-row">
														<div class="col-md-3">
															<div class="form-group">
																<multiselect id="freight_pelayaran_name"
																						 v-model="inputFreightPelayaran.name"
																						 placeholder="Kind of Charges" :searchable="true"
																						 :allow-empty="true" :clearable="true"
																						 :loading="isLoading" :close-on-select="true"
																						 :options="listItem" label="item_name" required>
																</multiselect>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<input v-model="inputFreightPelayaran.rate" type="text"
																			 class="form-control" placeholder="Rate" required />
															</div>
														</div>
														<div class="col-md-2">
															<multiselect id="freight_pelayaran_curr"
																					 v-model="inputFreightPelayaran.curr"
																					 placeholder="Currency" :searchable="true"
																					 :allow-empty="true" :clearable="true"
																					 :loading="isLoading" :close-on-select="true"
																					 :options="listCurrency" label="code" required>
															</multiselect>
														</div>
														<div class="col-md-3">
															<div class="form-group">
																<input v-model="inputFreightPelayaran.remarks"
																			 type="text" class="form-control"
																			 placeholder="Remarks" required />
															</div>
														</div>
														<div class="col-md-2">
															<div class="row">
																<div class="col-md-4">
																	<a v-show="k || (!k && inputsFreightPelayaran.length > 1)"
																		 class="btn btn-light mb-2"
																		 @click="remove_freight_pelayaran(k)">
																		<i class="fas fa-minus-circle"></i>
																	</a>

																</div>
																<div class="col-md-4">
																	<a v-show="k === inputsFreightPelayaran.length - 1"
																		 class="btn btn-light"
																		 @click="add_freight_pelayaran(k)">
																		<i class="fas fa-plus-circle"></i>
																	</a>
																</div>
															</div>
														</div>
													</div>


												</b-card>
											</b-collapse>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
<script>
	import appConfig from '@src/app.config'
	import Layout from '@layouts/main'
	import PageHeader from '@components/page-header'
	import Multiselect from 'vue-multiselect'
	import tinymce from 'vue-tinymce-editor'
	import {
		mapState,
		mapActions
	} from 'vuex'

	export default {
		name: 'Formreate',
		page: {
			title: 'Quotation',
			meta: [{
				name: 'description',
				content: appConfig.description,
			}, ],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
			tinymce
		},
		data() {
			return {
				title: 'Detail Quotation',
				dataQuotation: {

				},
				textarea: '',
				value: null,
				value1: null,
				options: [
					'Alaska',
					'Hawaii',
					'California',
					'Nevada',
					'Oregon',
					'Washington',
					'Arizona',
					'Colorado',
					'Idaho',
					'Montana',
					'Nebraska',
					'New Mexico',
					'North Dakota',
					'Utah',
					'Wyoming',
					'Alabama',
					'Arkansas',
					'Illinois',
					'Iowa',
				],
				inputsLocalCharge: [{
					name: '',
					rate: '',
					curr: '',
					remarks: ''
				}],
				inputsLocalDestination: [{
					name: '',
					rate: '',
					curr: '',
					remarks: ''
				}],
				inputsCustomClearance: [{
					name: '',
					rate: '',
					curr: '',
					remarks: ''
				}],
				inputsFreightPelayaran: [{
					name: '',
					rate: '',
					curr: '',
					remarks: ''
				}],
				remarksOptions: {
					'height': 100
				},
				remarksOperations: {
					'height': 250
				},
				type_Akun: '',
				items: [{
					text: 'Master',
					href: '/',
				},
					{
						text: 'Quotation',
						href: '/quotation',
					},
					{
						text: 'Detail',
						active: true,
					},
				],

				dataNomorPenerimaanInv: {
					kode_penerimaan_id: 1,
				},
				dataNomorPenerimaanKui: {
					kode_penerimaan_id: 2,
				},
				isLoading: false,
			}
		},
		computed: {
			...mapState('customer', ['listCustomer']),
			...mapState('pol', ['listPol']),
			...mapState('pod', ['listPod']),
			...mapState('currency', ['listCurrency']),
			...mapState('item', ['listItem']),
			...mapState('term', ['listTerm']),

		},
		mounted() {
			this.getListCustomer()
			this.getListPol()
			this.getListPod()
			this.getListCurrency()
			this.getListItem()
			this.getListTerm()
		},

		methods: {
			...mapActions('customer', ['getListCustomer']),
			...mapActions('pol', ['getListPol']),
			...mapActions('pod', ['getListPod']),
			...mapActions('currency', ['getListCurrency']),
			...mapActions('item', ['getListItem']),
			...mapActions('term', ['getListTerm']),
			add(index) {
				this.inputsLocalCharge.push({
					name: '',
					rate: '',
					curr: '',
					remarks: ''
				});
			},
			remove(index) {
				this.inputsLocalCharge.splice(index, 1);
			},

			add_local_destination(index) {
				this.inputsLocalDestination.push({
					name: '',
					rate: '',
					curr: '',
					remarks: ''

				});
			},
			remove_local_destination(index) {
				this.inputsLocalDestination.splice(index, 1);
			},
			add_custom_clearance(index) {
				this.inputsCustomClearance.push({
					name: '',
					rate: '',
					curr: '',
					remarks: ''

				});
			},
			remove_custom_clearance(index) {
				this.inputsCustomClearance.splice(index, 1);
			},
			add_freight_pelayaran(index) {
				this.inputsFreightPelayaran.push({
					name: '',
					rate: '',
					curr: '',
					remarks: ''

				});
			},
			remove_freight_pelayaran(index) {
				this.inputsFreightPelayaran.splice(index, 1);
			},

			submitData() {
				// formmataAnggaran_id = formmataAnggaran.ids
				this.successAlert('Sukses Membuat Nomor Kuitansi Baru')
				console.log(this.dataQuotation)
				console.log(this.inputsLocalCharge)
				console.log(this.inputsLocalDestination)
				console.log(this.inputsCustomClearance)
				console.log(this.inputsFreightPelayaran)
				this.$router.push({
					name: 'Quotation List',
				})
				// this.dataQuotation = new FormData();

			},
		},
	}
</script>
